<template>
  <Register />
</template>

<script>
import Register from "@/components/Register.vue";

export default {
  name: "Home",
  components: {
    Register,
  },
};
</script>
