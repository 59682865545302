<template>
  <div class="flex flex-col justify-center py-20">
    <h2 class="text-center text-3xl font-extrabold text-gray-600">
      Welcome to the registration for the virtual
      <span class="text-brand-light">Trevira CS Club</span> event. The event
      will take place on <span class="text-brand-light">01.07.2021</span>. As we
      would like to send you a small surprise in advance by postal shipping for
      this event, we ask you to indicate the address where you can be found at
      the moment. This can be the address of your office, but also your private
      address in case you are currently working from home.
    </h2>
    <div
      style="max-width: 700px; margin: 5em auto 0 auto"
      class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
    >
      <form class="space-y-6 form" @submit.prevent="register">
        <div
          id="succ"
          class="hidden bg-green-50 border-l-4 border-green-400 p-4"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: check-circle -->
              <svg
                class="h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p id="succ-text" class="text-sm text-green-700">
                You have successfully registered, the event will take place on
                01.07.2021.
              </p>
            </div>
          </div>
        </div>

        <div id="err" class="hidden bg-red-50 border-l-4 border-red-400 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: x-circle -->
              <svg
                class="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p id="err-text" class="text-sm text-red-700"></p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label
                for="fname"
                class="block text-sm font-medium text-gray-700"
              >
                First name*
              </label>
              <div class="mt-1">
                <input
                  placeholder="First name"
                  id="fname"
                  name="fname"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label
                for="lname"
                class="block text-sm font-medium text-gray-700"
              >
                Last name*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Last name"
                  id="lname"
                  name="lname"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label
                for="company"
                class="block text-sm font-medium text-gray-700"
              >
                Company*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Company"
                  id="company"
                  name="company"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label for="tel" class="block text-sm font-medium text-gray-700">
                Telephone number
              </label>
              <div class="mt-1">
                <input
                  placeholder="Telephone number"
                  id="tel"
                  name="tel"
                  type="tel"
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-gray-700"
              >
                E-Mail*
              </label>
              <div class="mt-1">
                <input
                  placeholder="E-Mail"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-4">
            <div>
              <label
                for="street"
                class="block text-sm font-medium text-gray-700"
              >
                Street*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Street"
                  id="street"
                  name="street"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-2">
            <div>
              <label
                for="housenr"
                class="block text-sm font-medium text-gray-700"
              >
                Housenumber*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Housenumber"
                  id="housenr"
                  name="housenr"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-1">
            <div>
              <label for="plz" class="block text-sm font-medium text-gray-700">
                Postcode*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Postcode"
                  id="plz"
                  name="plz"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label for="city" class="block text-sm font-medium text-gray-700">
                City*
              </label>
              <div class="mt-1">
                <input
                  placeholder="City"
                  id="city"
                  name="city"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-2">
            <div>
              <label
                for="country"
                class="block text-sm font-medium text-gray-700"
              >
                Country*
              </label>
              <div class="mt-1">
                <input
                  placeholder="Country"
                  id="country"
                  name="country"
                  type="text"
                  required
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-brand-light
                    focus:border-brand-light
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="
            submit
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-brand-light
          "
        >
          <div
            class="
              loader
              hidden
              ease-linear
              rounded-full
              border-2 border-t-2 border-gray-200
              h-5
              w-5
              mr-2
            "
          ></div>
          Register
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  name: "Register",
  methods: {
    async register() {
      let email = document.querySelector("#email").value;
      let fname = document.querySelector("#fname").value;
      let lname = document.querySelector("#lname").value;
      let plz = document.querySelector("#plz").value;
      let housenr = document.querySelector("#housenr").value;
      let city = document.querySelector("#city").value;
      let street = document.querySelector("#street").value;
      let company = document.querySelector("#company").value;
      let tel = document.querySelector("#tel").value;
      let country = document.querySelector("#country").value;
      const data = {
        email,
        fname,
        lname,
        plz,
        city,
        street,
        company,
        tel,
        housenr,
        country,
      };
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      try {
        document.querySelector(".loader").classList.remove("hidden");
        await firebase.firestore().collection("users").add({
          email: email,
          fname: fname,
          lname: lname,
          plz: plz,
          city: city,
          street: street,
          company: company,
          tel: tel,
          housenr: housenr,
          country: country,
          creation: today,
        });

        await firebase.functions().httpsCallable("register")(data);

        document.querySelector(".loader").classList.add("hidden");
        document.querySelector("#succ").classList.remove("hidden");
        document.querySelector("#err").classList.add("hidden");
        document.querySelector(".form").reset();
      } catch (err) {
        this.whichErrorIsIt(err);
      }
    },
    whichErrorIsIt(err) {
      switch (err.code) {
        case "auth/email-already-in-use":
          document.querySelector("#err-text").innerHTML =
            "Diese E-Mail wird bereits verwendet.";
          break;
        case "auth/weak-password":
          document.querySelector("#err-text").innerHTML =
            "Das Passwort muss mindestens 6 Zeichen lang sein." +
            "Und es darf nicht leicht zu erraten sein.";
          break;
        case "auth/invalid-email":
          document.querySelector("#err-text").innerHTML =
            "Bitte geben Sie eine gültige E-Mail an.";
          break;
        default:
          document.querySelector("#err-text").innerHTML =
            "Es ist etwas schief gelaufen.";
          break;
      }
    },
  },
};
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
